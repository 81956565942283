import React, { Component } from 'react'
import { Router, NavLink } from 'react-router-dom'
import history from '../history'

//Components
import SideDrawer from '../mobileNavbar/sideDrawer.component'

//Styles
import './navbar.scss'

//Assets
import defaultMenuIcon from '../assets/menu_icon.svg'

class Navbar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sideDrawerOpen: false
    }
    this.drawerToggleClickHandler = this.drawerToggleClickHandler.bind(this)
  }

  drawerToggleClickHandler() {
    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen }
    })
  }

  render() {
    return (
      <nav className='navbar'>
        <Router history={history}>
          <div className='navbar-desktop'>
            <div className='navbar-left'>{this.props.logo}</div>
            <div className='navbar-main'>
              <ul>
                {this.props.navItems.map((item, index) => (
                  <li className='menu-item' key={`${index}`}>
                    {item}
                  </li>
                ))}
              </ul>
            </div>
            {this.props.navbarRight ? (
              <div className='navbar-right'>{this.props.navbarRight}</div>
            ) : (
              ''
            )}
          </div>
          <ul className='navbar-mobile'>
            <li className='menu-item'>{this.props.mobileNavItem}</li>
            <li className='burger menu-item'>
              <button onClick={this.drawerToggleClickHandler}>
                <img src={this.props.burger} alt='Hamburger menu icon' />
              </button>
            </li>
          </ul>
          <SideDrawer
            toggleOpen={this.drawerToggleClickHandler}
            show={this.state.sideDrawerOpen}
            navItems={this.props.navItems}
            topContainer={this.props.topContainer}
            closeButton={this.props.closeIcon}
            logo={this.props.logo}
            topContainerClass={this.props.topContainerClass}
            middleContainerClass={this.props.middleContainerClass}
            bottomContainerClass={this.props.bottomContainerClass}
            bottomContainer={this.props.bottomContainer}
          />
        </Router>
      </nav>
    )
  }
}

export default Navbar
