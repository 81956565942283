import React from 'react'

//Styles
import './card.scss'

const Card = (props) => (
  <div className={props.cardClass ? `${props.cardClass}` : `card`}>
    <div
      className={props.imageClass ? `${props.imageClass}` : 'image-container'}
    >
      {props.cardImage}
    </div>
    <div className={props.bodyClass ? `${props.bodyClass}` : 'body-container'}>
      {props.cardBody}
    </div>
  </div>
)
export default Card
