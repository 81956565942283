import React, { Component } from 'react'
import { Swipeable } from 'react-swipeable'

//Styles
import './testimonial_timer.scss'

class TestimonialTimer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      time: 0,
      start: 0,
      currentIndex: 0
    }
    this.setIndex = this.setIndex.bind(this)
    this.startTimer = this.startTimer.bind(this)
    this.resetTimer = this.resetTimer.bind(this)
  }

  componentDidMount() {
    this.startTimer()
  }
  componentDidUpdate() {
    if (this.state.time === this.props.intervalTime) {
      this.setIndex(this.state.currentIndex + 1)
    }
  }
  startTimer() {
    this.timer = setInterval(
      () =>
        this.setState({
          time: this.state.time + 1
        }),
      1000
    )
  }

  resetTimer() {
    this.setState({ time: 0 })
  }

  setIndex(index) {
    if (index < 0) {
      index = this.props.body.length - 1
    }

    if (index >= this.props.body.length) {
      index = 0
    }

    this.setState({
      currentIndex: index,
      time: Date.now() - this.state.start
    })
    this.resetTimer()
  }

  render() {
    const config = {
      onSwipedLeft: () => this.setIndex(this.state.currentIndex - 1),
      onSwipedRight: () => this.setIndex(this.state.currentIndex + 1),
      preventDefaultTouchmoveEvent: true,
      trackMouse: true
    }

    const { currentIndex } = this.state

    return (
      <div
        className={
          this.props.containerClass
            ? `${this.props.containerClass}`
            : 'testimonials-container'
        }
      >
        <Swipeable {...config}>
      <div
        className={
          this.props.innerContainerClass
            ? `${this.props.innerContainerClass}`
            : 'testimonials'
        }
      >
            <div className='quote-container'>{this.props.quoteIcon}</div>
            {this.props.header ? (
              <div
                className={
                  this.props.headerClass
                    ? `${this.props.headerClass}`
                    : 'testimonial-header'
                }
              >
                {this.props.header}
              </div>
            ) : (
              ''
            )}
            {this.props.body ? (
              <div
                className={
                  this.props.bodyClass
                    ? `${this.props.bodyClass}`
                    : 'testimonial-body'
                }
              >
                {this.props.body[this.state.currentIndex]}
                <div
                  className={
                    this.props.buttonClass
                      ? `${this.props.buttonClass}`
                      : 'buttons-container'
                  }
                >
                  {this.props.body.map((testimonial, index) => (
                    <input
                      type='radio'
                      name='index'
                      checked={currentIndex === index}
                      onClick={() => this.setIndex(index)}
                    ></input>
                  ))}
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </Swipeable>
      </div>
    )
  }
}

export default TestimonialTimer
