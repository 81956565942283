import React from 'react';
import '../form.scss';

export const select = (field) => (
    <div className={field.containerClass ? field.containerClass : "field-group"} style={field.containerStyle ? field.containerStyle : {}}>
        <label className={field.labelClass} >{field.label}</label>
        <div className="form-input-container">
          <select {...field.input}>
            {field.children}
          </select>
          <span className="form-error">{field.meta.touched && field.meta.error ? field.meta.error : ''}</span>
        </div>
    </div>
);