import React, { Component } from 'react';

// Styles
import './jumbotron.scss';

// Actions

// Components

class Jumbotron extends Component {

    render() {
        return (
            <div className={this.props.containerClass ? this.props.containerClass : "Jumbotron-container"}>
                <div className={this.props.jumbotronClass ? this.props.jumbotronClass : "jumbotron"}>
                    <div className={this.props.headerClass ? this.props.headerClass : "header"}>
                        {this.props.header}
                    </div>
                    {
                        this.props.showDivider ? <hr /> : ''
                    }
                    <div className={this.props.bodyClass ? this.props.bodyClass : "body"}>
                        {this.props.body}
                    </div>
                </div>
            </div>
        );
    }
}

export default Jumbotron;
