import React from 'react'

//Styles
import './footer.scss'

const Footer = (props) => (
  <footer
    className={
      props.containerClass ? `${props.containerClass}` : 'footer-container'
    }
  >
    {props.footerTop ? (
      <div
        className={
          props.footerTopClass ? `${props.footerTopClass}` : 'footer-top'
        }
      >
        {props.footerTop}
      </div>
    ) : (
      ''
    )}
    {props.footerMiddle ? (
      <div
        className={
          props.footerMiddleClass
            ? `${props.footerMiddleClass}`
            : 'footer-middle'
        }
      >
        {props.footerMiddle}
      </div>
    ) : (
      ''
    )}
    {props.footerBottom ? (
      <div
        className={
          props.footerBottomClass
            ? `${props.footerBottomClass}`
            : 'footer-bottom'
        }
      >
        {props.footerBottom}
      </div>
    ) : (
      ''
    )}
  </footer>
)
export default Footer
