import React from 'react'
import '../form.scss'
import defaultTipIcon from "../defaultIcon.svg";
export const input = ({
  input,
  label,
  containerClass,
  inputContainerClass,
  labelClass,
  placeholder,
  toolTipText,
  tipIcon,
  inputClass,
  type,
  disabled,
  autoComplete,
  meta: { asyncValidating, touched, error, warning }
}) => (
  <div className={containerClass ? containerClass : 'field-group'}>
    {toolTipText ? (
      <div className='toolTip'>
        <label className={labelClass}>{label}</label>
        <img src={tipIcon ? tipIcon : defaultTipIcon} className='toolTip-image' alt='tool tip' />
        <span className='toolTipText'>{toolTipText}</span>
      </div>
    ) : (
      <label className={labelClass}>{label}</label>
    )}
    <div
      className={
        inputContainerClass ? inputContainerClass : 'form-input-container'
      }
    >
      <input
        {...input}
        placeholder={placeholder ? placeholder : label}
        className={inputClass}
        type={type}
        disabled={disabled}
        autoComplete={autoComplete}
      />
      <span className='form-error'>
        {(touched && error) || asyncValidating ? error : ''}
      </span>
    </div>
  </div>
)
