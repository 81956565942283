import React from 'react'

//Styles
import './twoCol.scss'

const TwoCol = (props) => (
  <div
    className={
      props.containerClass ? `${props.containerClass}` : 'twoCol-container'
    }
  >
    <div
      className={
        props.leftColClass ? `${props.leftColClass}` : 'container-column'
      }
    >
      {props.leftColumn}
    </div>
    <div
      className={
        props.rightColClass ? `${props.rightColClass}` : 'container-column'
      }
    >
      {props.rightColumn}
    </div>
  </div>
)
export default TwoCol
