import React, { Component } from 'react'

//Styles
import './gallery.scss'

class Gallery extends Component {
  constructor(props) {
    super(props)

    this.state = {
      currentIndex: 0
    }
    this.setIndex = this.setIndex.bind(this)
  }

  setIndex(index) {
    if (index < 0) {
      index = this.props.gallery.length - 1
    }

    if (index >= this.props.gallery.length) {
      index = 0
    }

    this.setState({ currentIndex: index })
  }

  render() {
    const { currentIndex } = this.state

    return (
      <div
        className={
          this.props.containerClass
            ? `${this.props.containerClass}`
            : 'gallery-container'
        }
      >
        {this.props.header ? (
          <div
            className={
              this.props.headerClass
                ? `${this.props.headerClass}`
                : 'header-container'
            }
          >
            {this.props.header}
          </div>
        ) : (
          ''
        )}
        <div
          className={
            this.props.buttonContainerClass
              ? `${this.props.buttonContainerClass}`
              : 'button-container'
          }
        >
          <button
            type='button'
            className=''
            onClick={() => this.setIndex(this.state.currentIndex - 1)}
          >
            <img src={this.props.arrowLeft} alt='previous' />
          </button>
        </div>
        <div
          className={
            this.props.imageContainerClass
              ? `${this.props.imageContainerClass}`
              : 'featured-image'
          }
        >
          {this.props.gallery[this.state.currentIndex]}
        </div>
        <div
          className={
            this.props.mobileButtonContainerClass
              ? `${this.props.mobileButtonContainerClass}`
              : 'button-container-mobile'
          }
        >
          <button
            type='button'
            className=''
            onClick={() => this.setIndex(this.state.currentIndex - 1)}
          >
            <img src={this.props.arrowLeft} alt='previous' />
          </button>
          <button
            type='button'
            className=''
            onClick={() => this.setIndex(this.state.currentIndex + 1)}
          >
            <img src={this.props.arrowRight} alt='next' />
          </button>
        </div>
        <div
          className={
            this.props.buttonContainerClass
              ? `${this.props.buttonContainerClass}`
              : 'button-container'
          }
        >
          <button
            type='button'
            className=''
            onClick={() => this.setIndex(this.state.currentIndex + 1)}
          >
            <img src={this.props.arrowRight} alt='next' />
          </button>
        </div>
        <div
          className={
            this.props.bottomContainerClass
              ? `${this.props.bottomContainerClass}`
              : 'images-container'
          }
        >
          {this.props.gallery.map((gal, index) => (
            <div className='img' onClick={() => this.setIndex(index)}>
              {gal}
            </div>
          ))}
        </div>
      </div>
    )
  }
}

export default Gallery
