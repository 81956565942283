import React from 'react'

//Styles
import './multiCol.scss'

const MultiCol = (props) => (
  <div
    className={
      props.containerClass ? `${props.containerClass}` : 'multiCol-container'
    }
  >
    {props.header ? (
      <div
        className={
          props.headerClass ? `${props.headerClass}` : 'multiCol-header'
        }
      >
        {props.header}
      </div>
    ) : (
      ''
    )}
    {props.posts && props.posts.length > 0 ? (
      <div className={props.rowClass ? `${props.rowClass}` : 'container-row'}>
        {props.posts.map(
          ((item, index) => (
            <div
              className={
                props.columnClass ? `${props.columnClass}` : 'container-column'
              }
            >
              {item}
            </div>
          ): '')
        )}
      </div>
    ) : (
      <div
        className={
          props.errorClass ? `${props.errorClass}` : 'container-error'
        }
      >
        {props.errorMessage}
      </div>
    )}
    {props.footer ? (
      <div
        className={
          props.footerClass ? `${props.footerClass}` : `container-bottom`
        }
      >
        {props.footer}
      </div>
    ) : (
      ''
    )}
  </div>
)
export default MultiCol
