import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'

//Assets
import defaultCloseIcon from '../assets/close_button.svg'

class SideDrawer extends Component {
  render() {
    return (
      <div className={this.props.show ? 'sideDrawer open' : 'sideDrawer'}>
        <div
          className={
            this.props.topContainerClass
              ? `${this.props.topContainerClass}`
              : 'top-container'
          }
        >
          {this.props.topContainer}
          <div className='close-container'>
            <button onClick={this.props.toggleOpen} className='btn-close'>
              <img
                src={
                  this.props.closeButton
                    ? this.props.closeButton
                    : defaultCloseIcon
                }
                alt='Close Icon'
              />
            </button>
          </div>
        </div>
        {this.props.navItems ? (
          <div
            className={
              this.props.middleContainerClass
                ? `${this.props.middleContainerClass}`
                : `middle-container`
            }
          >
            <ul className='menu-container'>
              {this.props.navItems.map((item, index) => (
                <li className='menu-item' key={`${index}`}>
                  {item}
                </li>
              ))}
            </ul>
          </div>
        ) : (
          ''
        )}
        {this.props.bottomContainer ? (
          <div
            className={
              this.props.middleContainerClass
                ? `${this.props.bottomContainerClass}`
                : `bottom-container`
            }
          >
            {this.props.bottomContainer}
          </div>
        ) : (
          ''
        )}
      </div>
    )
  }
}
export default SideDrawer
